import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Main Menu',
    icon: UilCube,
    pages: [
      {
        name: 'dashboard',
        icon: 'message-square',
        path: '/main/dashboard',
        pathName: 'app-dashboard',
        active: true
      },
      {
        name: 'manufacturing',
        active: true,
        icon: 'shopping-cart',
        pages: [
          {
            name: 'dashboard',
            path: '/main/dashboard/e-management',
            pathName: 'e-management',
            active: true
          },
          {
            name: 'products',
            path: '/main/apps/e-commerce/admin/products',
            pathName: 'e-commerce-products',
            active: true
          },
          {
            name: 'orders',
            path: '/main/apps/e-commerce/admin/orders',
            pathName: 'e-commerce-orders',
            active: true
          },
          {
            name: 'refund',
            path: '/main/apps/e-commerce/admin/refund',
            pathName: 'e-commerce-refund',
            active: true
          }
        ]
      },
      {
        name: 'Customers',
        icon: 'user',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'app-customers',
        active: true
      },
      {
        name: 'Accounting',
        icon: 'clipboard',
        active: true,
        pages: [
          {
            name: 'Dashboard',
            path: '/main/apps/crm/analytics',
            pathName: 'accounting-dashboard',
            active: true
          },
          {
            name: 'Banking',
            path: '/main/apps/accounting/banking',
            pathName: 'accounting-banking',
            active: true
          },
          {
            name: 'Transactions',
            path: '/main/apps/crm/report-details',
            pathName: 'project-management-project-card-view',
            active: true
          },
          {
            name: 'Journal Entry',
            path: '/main/apps/accounting/journal-entry',
            pathName: 'journal-entry',
            active: true
          },
          {
            name: 'Transfer',
            path: '/main/apps/accounting/transfer',
            pathName: 'transfer',
            active: true
          },
          {
            name: 'Chart of Accounts',
            path: '/main/apps/project-management/project-details',
            pathName: 'project-management-project-details',
            active: true
          },
          {
            name: 'Reconcile',
            path: '/main/apps/accounting/reconcile',
            pathName: 'reconcile',
            active: true
          },
          {
            name: 'Budget',
            path: '/main/apps/accounting/budget',
            pathName: 'budget',
            active: true
          },
          {
            name: 'Reports',
            path: '/main/apps/crm/reports',
            pathName: 'project-management-project-details',
            active: true
          }
          // {
          //   name: 'Settings',
          //   path: '/main/apps/project-management/project-details',
          //   pathName: 'project-management-project-details',
          //   active: true
          // }
        ]
      },
      {
        name: 'Sales',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Proposals',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Estimates',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'Invoices',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Payments',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Credit Notes',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Items',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'Subscriptions',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Expenses',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Contracts',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Purchase',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Items',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Vendors',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'Vendor-Items',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Purchase Request Quotations',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Purchase Orders',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Order Returns Contracts',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Debit Notes',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Invoices',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Reports',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'Timesheet & Leave',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Attendance',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Leave',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'Work Shift Table',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Shift',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Shift Category',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Reports',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'Inventory',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Items',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Inventory Receiving Vouchers',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'Inventory Delivery Vouchers',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Packing List',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Internal Delivery Note',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Loss & Adjustment',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Receving - Exporting Return Order',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Warehouse',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Inventory History',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Reports',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'HR & Payroll',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Employees',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Attendance',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'Commissions',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Deductions',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Bonus kpi',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Insurance Payslip',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Payslip Templates',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Income Taxes',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Reports',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'Staff Workload',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Overview',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Settings',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          }
        ]
      },
      {
        name: 'Projects',
        path: '/main/apps/project-management/project-list-view',
        pathName: 'projects',
        icon: 'user',
        active: true
      },
      {
        name: 'Tasks',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Support',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Leads',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Estimate Request',
        path: '/main/apps/e-commerce/admin/customers',
        pathName: 'e-commerce-customers',
        icon: 'user',
        active: true
      },
      {
        name: 'Knowledge Base',
        path: '/main/pages/faq/faq-tab',
        pathName: 'knowledge-base',
        icon: 'user',
        active: true
      },
      {
        name: 'Utilities',
        icon: 'mail',
        active: true,
        pages: [
          {
            name: 'Media',
            path: '/main/apps/email/inbox',
            pathName: 'email-inbox',
            active: true
          },
          {
            name: 'Bulk PDF Export',
            path: '/main/apps/email/email-detail',
            pathName: 'email-detail',
            active: true
          },
          {
            name: 'CSV Export',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Calendar',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Announcements',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Activity Log',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Surveys',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Database Backup',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          },
          {
            name: 'Ticeket Pipe Log',
            path: '/main/apps/email/compose',
            pathName: 'email-compose',
            active: true
          }
        ]
      },
      {
        name: 'events',
        icon: 'bookmark',
        active: true,
        pages: [
          {
            name: 'create an event',
            path: '/main/apps/events/create-an-event',
            pathName: 'create-an-event',
            active: true
          },
          {
            name: 'Event detail',
            path: '/main/apps/events/event-detail',
            pathName: 'event-detail',
            active: true
          }
        ]
      },
      {
        name: 'Reports',
        icon: 'trello',
        active: true,
        pages: [
          {
            name: 'Sales',
            path: '/main/apps/kanban/kanban',
            pathName: 'app-kanban',
            active: true
          },
          {
            name: 'Expenses',
            path: '/main/apps/kanban/boards',
            pathName: 'kanban-boards',
            active: true
          },
          {
            name: 'Expenses vs Income',
            path: '/main/apps/kanban/create-board',
            pathName: 'create-kanban-board',
            active: true
          },
          {
            name: 'Leads',
            path: '/main/apps/kanban/create-board',
            pathName: 'create-kanban-board',
            active: true
          },
          {
            name: 'Timesheet Overview',
            path: '/main/apps/kanban/create-board',
            pathName: 'create-kanban-board',
            active: true
          },
          {
            name: 'KB Articles',
            path: '/main/apps/kanban/create-board',
            pathName: 'create-kanban-board',
            active: true
          }
        ]
      }
    ]
  }
];
